body {
  margin: 0 !important;
}

@font-face {
  font-family: 'PoppinsLight';
  src: local('PoppinsLight'),
    url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: local('PoppinsBold'),
    url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'),
    url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
