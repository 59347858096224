.circle-loader {
  width: 50px;
  height: 50px;
  stroke-width: 10px;
  stroke: #fff;
  fill: transparent;
  stroke-dasharray: 80;
  animation: rot 2s infinite linear, clr 2s infinite linear;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 160;
  }

  100% {
    transform: rotate(360deg);
    stroke-dashoffset: -160;
  }
}

.validation-loader .circle-loader {
  height: 120px;
  width: 120px;
}
