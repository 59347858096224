.language-list-container {
    display: flex;
}

.language-list-container .language-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 10px 5px;
    background-color: #fff;
    border: 1px solid #0074a6;
    position: absolute;
    top: -4px;
    right: -6px;
    z-index: 1;
}

.language-list-container .lang__flag {
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: contain;
    cursor: pointer;
}

.language-list-container .lang__flag--de {
    background-image: url('../../../assets/flags-svg/de.svg');
}

.language-list-container .lang__flag--en {
    background-image: url('../../../assets/flags-svg/en.svg');
}

.language-list-container .lang__flag--tr {
    background-image: url('../../../assets/flags-svg/tr.png');
}

.language-list-container .lang__flag--es {
    background-image: url('../../../assets/flags-svg/es.svg');
}

.language-list-container .lang__flag--hr {
    background-image: url('../../../assets/flags-svg/hr.svg');
}

.language-list-container .lang__flag--nl {
    background-image: url('../../../assets/flags-svg/nl.svg');
}

.language-list-container .lang__flag--pl {
    background-image: url('../../../assets/flags-svg/pl.svg');
}

.language-list-container .lang__flag--rs {
    background-image: url('../../../assets/flags-svg/rs.svg');
}

.language-list-container .lang__flag--ru {
    background-image: url('../../../assets/flags-svg/ru.svg');
}

@media screen and (min-width: 768px) {
    .language-list-container .language-list {
        top: -9px;
        right: -6px;
    }

    .language-list-container .lang__flag {
        width: 55px;
        height: 55px;
    }
}